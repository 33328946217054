<template>
    <section v-if="data.content.map_url !== '-'"
        :id="id"
        class="SectionMap"
        v-viewport-size:debounce.100='viewport'
    >
        <div class="SectionMap-GoogleMap item-transition">
            <!-- <iframe src="" frameborder="0"></iframe> -->
            <div ref="map" class="g-map"></div>
        </div>
        <div ref="mapInfo" class="SectionMap-Content item-transition">
            <h1 class="item-transition">Ubicación</h1>
            <h2 class="item-transition">{{ data.content.name }}</h2>
            <address
                class="SectionMap-Address item-transition"
            >
                <a :href="`tel:${data.content.phone}`">
                    Tel. {{ data.content.phone }}
                </a>
                <div v-html="data.content.address"></div>
            </address>
            <a
                class="SectionMap-DirectionsLink item-transition"
                target="_blank"
                :href="data.content.map_url"
            >Cómo llegar</a>
        </div>
    </section>
</template>

<script>
import inView from "in-view"
import loadGoogleMapsApi from 'load-google-maps-api'
import SectionUtils from '@/app/mixins/SectionUtils'
import mapStyle from '@/app/utils/map-style'
import mapPinNormal from 'assets/img/map-pin.png'
import mapPinHighlight from 'assets/img/map-pin-highlight.png'
import ComplexUtils from '@/app/mixins/ComplexUtils'

//const API_KEY = 'AIzaSyDwLthIGqp7-CdlqRNI9n3f79vg_WsP5tU'
//const API_KEY = 'AIzaSyCHGrAgG0ds83AuRiw93fGgvDOwSm7WhZA'
//const API_KEY = 'AIzaSyAFgXVMGFxco-rLXxNrpPS6ZbKdgN8ncLw'
//const API_KEY = 'AIzaSyBE5kapRLFZ8z8lwK9QcdLM4nzHTUYqSs4'
const API_KEY = 'AIzaSyBPidTeG6EJs7QWi9ZNFNoFVmqqy7LT4_Q';

const DEFAULT_ZOOM = 15

export default {
    name: 'SectionMap',
    mixins: [
        SectionUtils,
        ComplexUtils,
    ],
    data() {
        return {
            api: null,
            map: null,
            marker: null,
            ready: false,
            target: {
                lng: 19.3910038,
                lat: -99.2836971,
            },
            viewport: {
                height: 100,
                // apply height style only if above mobile cutoff
                predicate: w => w > 830,
                onResize: this.centerMap,
            },
        }
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            default: () => ({
                lng: 19.3910038,
                lat: -99.2836971,
                content: {
                    name: '',
                    phone: '',
                    address: [],
                }
            })
        },
    },
    async mounted() {
        if (!this.isPrerender) {
            await this.bindMap()
            this.createMarker()
            this.ready = true
            this.onResize()
            inView('.SectionMap .item-transition').on('enter', (el) => {
                el.classList.add("inview");
            });
        }
    },
    methods: {
        async bindMap() {
            const { map: el } = this.$refs
            this.api = await loadGoogleMapsApi({
                key: API_KEY,
                libraries: ['marker','places']
            })
            this.map = new this.api.Map(el, {
                mapId: '1fd105e15c823062',
                //styles: mapStyle,
                center: this.target,
                zoom: DEFAULT_ZOOM,
                disableDefaultUI: true,
                zoomControl: true,
                draggable: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            return new Promise((resolve) => {
                this.map.addListener('projection_changed', resolve)
            })
        },
        createMarker() {
            const { data, api, map } = this
            const position = new api.LatLng(data.lat, data.lng)
            const icon = this.complex.highlight ? mapPinHighlight : mapPinNormal
            this.marker = new api.marker.AdvancedMarkerElement({
                position,
                map,
            })/*
            console.log(icon);*/
            /*this.marker = new api.Marker({
                position,
                icon,
                map,
            })*/
        },
        centerMap() {
            if (this.ready) {
                this.centerToMarker(0)
            }
        },
        centerToMarker(offsetX, offsetY = 0) {
            const { map, api, marker, data } = this
            const position = new api.LatLng(data.lat, data.lng)
            const p1 = map.getProjection().fromLatLngToPoint(position)
            const p2 = new api.Point(
                offsetX / (2 ** map.getZoom()),
                offsetY / (2 ** map.getZoom()),
            )
            const translatedPoint = new api.Point(p1.x - p2.x, p1.y + p2.y)
            const pTarget = map.getProjection().fromPointToLatLng(translatedPoint)
            map.setCenter(pTarget)
        },
        onResize() {
            this.centerMap()
        }
    },
    computed: {
    },
}
</script>

<style src="styles/components/sections/SectionMap.styl" lang="stylus"></style>
