<template>
    <intersect
        :threshold="[0.1]"
        @enter="onFooterIntersects"
        @leave="onFooterLeaveIntersects"
    >
        <footer class="LayoutFooter">
            <nav class="LayoutFooter-Nav">
                <div class="LayoutFooter-List item-transition colums">
                    <div class="LayoutFooter-Menu">
                        <template   v-for="(complex, index) in data.complexes.items"  :key="index">
                            <div  v-if="index <= 6" :class="index <= 6 ? `LayoutFooter-ItemList  ItemList14 ` :`LayoutFooter-ItemList  es-style `">
                                <router-link :to="complex.path"  v-html=" complex.name">
                                </router-link>
                            </div>
                        </template>
                    </div>
                    <div class="LayoutFooter-Menu Menu-center">
                        <template   v-for="(complex, index) in data.complexes.items"  :key="index">
                        <div  v-if="index > 6" :class="index <= 5 ? `LayoutFooter-ItemList  ItemList14` :`LayoutFooter-ItemList`">
                            <router-link :to="complex.path"  v-html=" complex.name">
                            </router-link>
                        </div>
                    </template>
                    <div class="LayoutFooter-ItemList ItemList14" style="text-align:center">
                                <a target="_blank" href="https://begrand.es">
                                    PARK COUNTRY <br/> (GUADALAJARA)
                                </a>
                        </div>
                        <div class="LayoutFooter-ItemList ItemList14" style="text-align:center">
                            <a target="_blank" href="https://begrand.es">
                                DISTRITO SALAMANCA <br/>(MADRID, ESPAÑA)
                            </a>
                        </div> 
                    </div>

                    
                    <!-- <li class="LayoutFooter-ItemList ItemList14" style="text-align:center">
                        <a target="_blank" href="https://begrand.es">
                            PARK COUNTRY <br/> (GUADALAJARA)
                        </a>
                    </li>
                    <li class="LayoutFooter-ItemList ItemList14" style="text-align:center">
                        <a target="_blank" href="https://begrand.es">
                            DISTRITO SALAMANCA <br/>(MADRID, ESPAÑA)
                        </a>
                    </li> -->
                </div>
                <!-- <ul class="LayoutFooter-List item-transition">
                    <li
                        class="LayoutFooter-ItemList  es-style"
                        v-for="(complex, index) in data.complexes.items"
                        :key="index"
                    >
                        <router-link :to="complex.path"  v-html=" complex.name">
                        </router-link>
                    </li>
                </ul>
                <ul class="LayoutFooter-List item-transition">
                    <li class="LayoutFooter-ItemList es-style" style="text-align:center">
                        <a target="_blank" href="https://begrand.es">
                            DISTRITO SALAMANCA <br/>(MADRID, ESPAÑA)
                        </a>
                    </li>
                </ul> -->
                <hr class="LayoutFooter-Ruler">
                <ul class="LayoutFooter-List item-transition">
                    <li
                        class="LayoutFooter-ItemList"
                        v-for="(item, index) in data.site_menu.items"
                        :key="index"
                    >
                        <router-link
                            v-if="item.path"
                            :to="item.path"
                            @click.native="onItemClick"
                        >
                            {{ item.name }}
                        </router-link>
                        <a
                            v-else
                            :href="item.href"
                            :target="item.target"
                        > {{ item.name }}</a>
                    </li>
                </ul>
            </nav>
            <div class="LayoutFooter-InfoBar item-transition">
                <social-list class="LayoutFooter-SocialList"/>
                <hr class="LayoutFooter-Ruler">
                <div
                    class="LayoutFooter-Text"
                    v-html="data.footer.copyright"
                ></div>
                <ul class="LayoutFooter-InfoBar-List">
                    <li
                        class="LayoutFooter-InfoBar-ListItem"
                        v-for="(item, index) in data.footer.items"
                        :key="index"
                    >
                        <a
                            v-if="item.target === '_blank'"
                            :href="item.path"
                            target="_blank"
                        >
                            {{ item.name }}
                        </a>
                        <router-link v-else
                            :to="item.path"
                            @click.native="onItemClick"
                        >
                            {{ item.name }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </footer>
    </intersect>
</template>

<script>
import inView from "in-view"
import { LAYOUT_SCROLL_MENU } from '@/app/store/modules/app'
import SocialList from '@/app/components/SocialList.vue'
import Intersect from 'vue-intersect'

export default {
    name: 'LayoutFooter',
    computed: {
        data() {
            return this.$store.state.app.data
        },
    },
    mounted() {
        inView('.LayoutFooter .item-transition').on('enter', (el) => {
            el.classList.add("inview");
        });
    },
    components: {
        SocialList,
        Intersect,
    },
    methods: {
        onItemClick() {
            this.scrollToHash(true)
        },
        onFooterIntersects() {
            // this.$store.dispatch(LAYOUT_SCROLL_MENU, false)
        },
        onFooterLeaveIntersects() {
            // this.$store.dispatch(LAYOUT_SCROLL_MENU, true)
        }
    }
}
</script>

<style src="styles/components/layout/LayoutFooter.styl" lang="stylus"></style>

<style>
.es-style{
    margin-top:30px;
}
@media (max-width: 575.98px) {
    .es-style{
        margin-top:0px;
    }
}

</style>
